<template>
    <div class="m-steps-area" :style="`width: ${totalWidth};`">
        <div class="m-steps">
            <div :class="['m-steps-item',
          {
            'finish': currentStep >= index + 1,
             'harf-finish':currentStep > index + 1,
            'wait': currentStep < index + 1
          }
        ]" v-for="(step, index) in steps" :key="index">
                <div class="m-info-wrap">
                    <div class="m-steps-icon">
                        <span class="u-num" >{{ index + 1 }}</span>
                    </div>
                    <div class="m-steps-content">
                        <div class="u-steps-title" style="font-weight:600;">{{ step.title }}</div>
                        <div class="u-steps-description" v-show="step.description" :style="`max-width: ${descMaxWidth}px;`">{{ step.description }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        steps: {
            type: Array,
            default: () => []
        },
        current: {
            type: Number,
            default: 1
        },
        width: {
            type: [String, Number],
            default: '100%'
        },
        descMaxWidth: {
            type: [String, Number],
            default: 120
        }
    },

    computed: {
        totalWidth() {
            if (typeof this.$props.width === 'number') {
                return this.$props.width + 'px'
            } else {
                return this.$props.width
            }
        },
        totalSteps() {
            return this.$props.steps.length
        },
        currentStep() {
            if (this.$props.current < 1) {
                return 1
            } else if (this.$props.current > this.totalSteps.value + 1) {
                return this.totalSteps.value + 1
            } else {
                return this.$props.current
            }
        }
    },
    methods: {

    }


}



</script>

<style lang="scss" scoped>
.m-steps-area {
    margin: 0 auto;
    .m-steps {
        display: flex;
        .m-steps-item {
            display: inline-block;
            overflow: hidden;
            font-size: 16px;
            &:not(:last-child) {
                margin-right: 16px;
                flex: 1; // 弹性盒模型对象的子元素都有相同的长度，且忽略它们内部的内容
                .u-steps-title {
                    &::after {
                        background: #e8e8e8;
                        position: absolute;
                        top: 16px;
                        left: 130%;
                        display: block;
                        width: 350px;
                        height: 1px;
                        content: '';
                        cursor: auto;
                        transition: all 0.3s;
                        height: 2px;
                    }
                }
            }
            .m-info-wrap {
                display: inline-block;
                .m-steps-icon {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 8px;
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    text-align: center;
                    background-color: rgba(0, 0, 0, 0.06);
                    border: 1px solid transparent;
                    transition: all 0.3s;
                    .u-num {
                        display: inline-block;
                        font-size: 16px;
                        line-height: 1;
                        color: #fff;
                        transition: all 0.3s;
                    }
                    .u-icon {
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                    }
                }
                .m-steps-content {
                    display: inline-block;
                    vertical-align: top;
                    .u-steps-title {
                        position: relative;
                        display: inline-block;
                        padding-right: 16px;
                        color: rgba(0, 0, 0, 0.45);
                        line-height: 32px;
                        transition: all 0.3s;
                    }
                    .u-steps-description {
                        font-size: 14px;
                        color: rgba(0, 0, 0, 0.45);
                        line-height: 22px;
                        word-break: break-all;
                        transition: all 0.3s;
                    }
                }
            }
        }
        .harf-finish{
          .u-steps-title {
                        color: #333;
                        &::after {
                            background:#1A9DFF !important;
                        }
                    }
        }
        .finish {
            .u-num {
                color: #fff;
            }
            .m-info-wrap {
                cursor: pointer;
                .m-steps-icon {
                    background-color: #1a9dff;
                    border-color: #1a9dff;
                }

                .m-steps-content {
                    .u-steps-title {
                        color: #333;
                        &::after {
                            background: linear-gradient(to right, #1A9DFF 50%, #E5E5E5 0);
                        }
                    }

                    .u-steps-description {
                        color: rgba(0, 0, 0, 0.45);
                    }
                }
                // &:hover {
                //     .m-steps-icon {
                //         border-color: #1A9DFF;
                //     }
                //     .m-steps-content {
                //         .u-steps-title {
                //             color: #1A9DFF;
                //         }
                //         .u-steps-description {
                //             color: #1A9DFF;
                //         }
                //     }
                // }
            }
        }
        .process {
            .m-info-wrap {
                .m-steps-icon {
                    // background: #1a9dff;
                    // border: 1px solid rgba(0, 0, 0, 0.25);
                    // border-color: #1a9dff;
                    .u-num {
                        color: #fff;
                    }
                }
                .m-steps-content {
                    .u-steps-title {
                        color: #333;
                    }
                    .u-steps-description {
                        // color: #1A9DFF;
                    }
                }
            }
        }
        .wait {
            .m-info-wrap {
                cursor: pointer;

                .m-steps-icon {
                    // border-color: #1A9DFF;
                    .u-num {
                        color: #fff;
                    }
                }
                .m-steps-content {
                    .u-steps-title {
                        color: #333;
                    }
                    .u-steps-description {
                        // color: #1A9DFF;
                    }
                }
            }
        }
    }
}
</style>